@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFW50e.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UNirkOXOhs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOXOhs.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
  color: #000;
}
body a {
  text-decoration: none !important;
  color: #000;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
nav.section-header {
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.04), 0 10px 12px rgba(0, 0, 0, 0.03);
  margin-bottom: 0.5px;
  position: relative;
}
nav.section-header .header-top {
  width: 100%;
  padding: 10px 0;
  background-color: #c4e1ed;
}
nav.section-header .header-top ul {
  display: flex;
  justify-content: space-between;
  padding: 0 65px;
}
nav.section-header .header-top ul li a,
nav.section-header .header-top ul li p {
  font-weight: 400;
  font-size: 13px;
  transition: color 350ms;
  display: flex;
  align-items: center;
}
nav.section-header .header-top ul li a img,
nav.section-header .header-top ul li p img {
  width: 10px;
  margin-right: 10px;
}
nav.section-header .header-top ul li:hover a {
  color: #4d95b3;
}
nav.section-header .header-top ul li .address {
  display: flex;
  transform: translatex(-20px);
}
nav.section-header .header-top ul li .address img {
  width: 10px;
  margin-right: 10px;
}
nav.section-header .header-flexbox {
  display: flex;
  padding: 20px 0 10px 0;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-logo-mobile {
  display: none;
}
nav.section-header .header-flexbox .flexbox-logo-mobile img {
  width: 200px;
  transform: translatey(-10px);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper {
  width: 100%;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 30px;
  left: -5px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
  width: 100%;
  position: relative;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .color-element-mobile {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 65px;
  height: 100%;
  background-color: #4d95b3;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.flexbox-logo img {
  width: 200px;
  transform: translatey(-10px);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 600;
  transition: color 350ms;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:hover a {
  color: #4d95b3;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-toggle {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 350ms;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-toggle:hover {
  color: #4d95b3;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-toggle::after {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-menu {
  position: relative;
  background: #7aadc2;
  margin-top: 10px;
  border: none;
  border-radius: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-menu:before {
  content: '';
  display: block;
  position: absolute;
  left: 25px;
  top: -5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent rgba(122, 173, 194, 0.8) transparent;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-menu .dropdown-item {
  padding: 10px 15px;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 600;
  transition: all 0.3s;
  transform: none !important;
  color: #000;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop .dropdown-menu .dropdown-item:hover {
  background: #c4e1ed;
}
nav.section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 1px;
  width: 24px;
  background: #000;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar,
nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
.btn {
  border: 0;
  background: 0;
  border-radius: 0;
  padding: 10px 30px;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 350ms;
}
.btn.btn-default {
  border: 1px solid #000;
}
.btn:hover {
  background-color: #f9f9f9;
  color: #4d95b3;
}
.btn.light:hover {
  background-color: #fff;
}
.section-heading {
  width: 100%;
  padding: 60px 0;
  background-color: #c4e1ed;
  display: flex;
  align-items: center;
}
.section-heading h1 {
  font-size: 44px;
  font-weight: 600;
  width: 59%;
  margin: 0 auto;
}
.fancybox-caption__body {
  font-size: 26px;
}
.image-background {
  position: absolute;
  bottom: -50px;
  left: -100px;
  z-index: -1;
  width: 110%;
  height: 120%;
  background-color: #c4e1ed;
}
section.section-welcome {
  height: 80vh;
}
section.section-welcome .welcome-background {
  width: 100%;
  height: 100%;
  background-color: #c4e1ed;
}
section.section-welcome .welcome-wrapper {
  height: 100%;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome {
  height: 100%;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .owl-stage-outer,
section.section-welcome .welcome-wrapper .owl-carousel-welcome .owl-stage,
section.section-welcome .welcome-wrapper .owl-carousel-welcome .owl-item {
  height: 100%;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .item {
  height: 100%;
  display: flex;
  align-items: center;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text {
  position: relative;
  z-index: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transform: translatex(190px);
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text h2 {
  font-weight: 600;
  font-size: 60px;
  line-height: 60px;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text p {
  font-size: 17px;
  text-align: center;
  width: 100%;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text .btn-default {
  margin-top: 40px;
  align-self: center;
  transition: color 350ms;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text .btn-default:hover {
  color: #4d95b3;
}
section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-img {
  width: 55%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.section-about {
  padding-top: 150px;
}
.section-about .about-wrapper {
  display: flex;
  position: relative;
  align-items: center;
}
.section-about .about-wrapper .line {
  position: absolute;
  left: -500px;
  top: 200px;
  width: 800px;
  height: 1px;
  background-color: #000;
}
.section-about .about-wrapper .about-img {
  position: relative;
  z-index: 1;
  width: 40%;
  height: 480px;
}
.section-about .about-wrapper .about-img .lines {
  position: absolute;
  top: -480px;
  left: -200px;
  height: 650px;
  width: 400px;
  border-left: 1.5px solid #000;
  border-bottom: 1.5px solid #000;
  opacity: 0.7;
  z-index: -1;
}
.section-about .about-wrapper .about-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0 30%;
}
.section-about .about-wrapper .about-text {
  width: 60%;
  padding: 0 0 0 60px;
}
.section-about .about-wrapper .about-text h2 {
  font-size: 28px;
  margin-bottom: 30px;
  line-height: 44px;
}
.section-about .about-wrapper .about-text h2 strong {
  font-size: 42px;
}
.section-about .about-wrapper .about-text h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.section-about .about-wrapper .about-text p {
  font-size: 15px;
  line-height: 26px;
}
.section-about .about-wrapper .about-text p:not(:first-of-type) {
  margin-top: 10px;
}
.section-about .about-wrapper .about-text p strong {
  font-size: 16px;
}
.section-about .about-wrapper .about-text a {
  font-weight: 700;
  transition: color 350ms;
}
.section-about .about-wrapper .about-text a:hover {
  color: #4d95b3;
}
.section-about .about-wrapper .about-text .btn-default {
  margin-top: 30px;
  font-weight: 600;
}
.section-offer {
  padding-top: 150px;
}
.section-offer .offer-wrapper {
  display: flex;
  height: 450px;
}
.section-offer .offer-wrapper .offer-content {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.section-offer .offer-wrapper .offer-content .offer-item {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #c4e1ed;
  transition: background-color 350ms;
  margin-top: 3px;
}
.section-offer .offer-wrapper .offer-content .offer-item .icon {
  position: relative;
}
.section-offer .offer-wrapper .offer-content .offer-item .icon .circle {
  position: absolute;
  left: -15px;
  top: -15px;
  width: 75px;
  height: 75px;
  border-radius: 100px;
  background-color: #4d95b3;
  transition: all 350ms;
}
.section-offer .offer-wrapper .offer-content .offer-item .icon img {
  position: relative;
  z-index: 1;
  width: 45px;
  opacity: 0.7;
}
.section-offer .offer-wrapper .offer-content .offer-item h3 {
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 30px;
  text-align: center;
}
.section-offer .offer-wrapper .offer-content .offer-item:hover {
  background-color: rgba(196, 225, 237, 0.3);
}
.section-offer .offer-wrapper .offer-content .offer-item:hover .circle {
  width: 80px;
  height: 80px;
  left: -17px;
  top: -17px;
}
.section-offer .offer-wrapper .offer-img {
  width: 40%;
  margin-top: 3px;
}
.section-offer .offer-wrapper .offer-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section-offer .offer-wrapper .offer-heading {
  width: 40%;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 70px;
  text-align: center;
}
.section-offer .offer-wrapper .offer-heading h2 {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 20px;
  line-height: 48px;
  width: 80%;
  font-weight: 700;
  font-size: 38px;
}
.section-offer .offer-wrapper .offer-heading p {
  font-size: 15px;
}
.section-offer .offer-wrapper .offer-heading .btn-default {
  margin-top: 40px;
}
.section-gallery {
  padding-top: 80px;
}
.section-gallery h2 {
  font-size: 42px;
  font-weight: 700;
  text-align: center;
  padding: 30px 0 40px 0;
}
.section-gallery .gallery-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.section-gallery .gallery-wrapper .item {
  position: relative;
  width: 24.8%;
  margin-top: 5px;
  height: 320px;
}
.section-gallery .gallery-wrapper .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 250ms;
}
.section-gallery .gallery-wrapper .item .img-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(77, 149, 179, 0.5);
  transition: all 350ms;
}
.section-gallery .gallery-wrapper .item .img-background:hover {
  background-color: transparent;
}
.section-gallery .gallery-wrapper .item:nth-of-type(odd) .img-background {
  background-color: rgba(77, 149, 179, 0.2);
}
.section-gallery .gallery-wrapper .item:nth-of-type(odd) .img-background:hover {
  background-color: transparent;
}
section.section-testimonials {
  padding-top: 100px;
}
section.section-testimonials .owl-carousel-testimonials .item {
  width: 70%;
  margin: 0 auto 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.section-testimonials .owl-carousel-testimonials .item img {
  width: 70px;
  opacity: 0.7;
  margin-bottom: 30px;
}
section.section-testimonials .owl-carousel-testimonials .item h3 {
  font-size: 22px;
  margin-bottom: 10px;
}
section.section-testimonials .owl-carousel-testimonials .item p {
  text-align: center;
  font-size: 15px;
  line-height: 26px;
  margin-top: 10px;
}
section.section-testimonials .owl-carousel-testimonials .item span {
  font-size: 20px;
  font-weight: 700;
  display: block;
  text-align: center;
  margin-top: 40px;
}
section.section-testimonials .owl-carousel-testimonials .owl-dots {
  width: 20%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}
section.section-testimonials .owl-carousel-testimonials .owl-dots .owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 0;
  border: 1px solid #000;
}
section.section-testimonials .owl-carousel-testimonials .owl-dots .owl-dot.active {
  width: 18px;
  height: 18px;
  border: none;
  background-color: #4d95b3;
}
section.section-pricelist {
  padding-top: 100px;
}
section.section-pricelist .heading,
section.cennik-section-content .heading {
  text-align: center;
}
section.section-pricelist .heading h2,
section.cennik-section-content .heading h2 {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 20px;
}
section.section-pricelist .heading p,
section.cennik-section-content .heading p {
  font-size: 15px;
  line-height: 26px;
}
section.section-pricelist .pricelist-column,
section.cennik-section-content .pricelist-column {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0 auto;
  padding-top: 40px;
}
section.section-pricelist .pricelist-column .pricelist-item,
section.cennik-section-content .pricelist-column .pricelist-item {
  display: flex;
  flex-direction: column;
}
section.section-pricelist .pricelist-column .content-heading,
section.cennik-section-content .pricelist-column .content-heading {
  padding: 60px 0 40px 0;
}
section.section-pricelist .pricelist-column .content-heading h2,
section.cennik-section-content .pricelist-column .content-heading h2 {
  text-align: center;
  font-weight: 600;
}
section.section-pricelist .pricelist-column .content-row,
section.cennik-section-content .pricelist-column .content-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #4d95b3;
  padding-bottom: 10px;
  margin: 10px 0;
}
section.section-pricelist .pricelist-column .content-row .offer,
section.cennik-section-content .pricelist-column .content-row .offer {
  display: inline-block;
  font-size: 15px;
  text-align: left;
}
section.section-pricelist .pricelist-column .content-row .price,
section.cennik-section-content .pricelist-column .content-row .price {
  font-weight: 600;
  font-size: 15px;
  text-align: right;
}
section.section-pricelist .pricelist-column .btn-default,
section.cennik-section-content .pricelist-column .btn-default {
  margin-top: 40px;
  align-self: center;
}
section.o-klinice-section-content .section-wrapper,
section.offer-section-content .section-wrapper {
  padding-top: 80px;
  display: flex;
}
section.o-klinice-section-content h2,
section.offer-section-content h2 {
  font-size: 42px;
  font-weight: 700;
}
section.o-klinice-section-content .section-offer,
section.offer-section-content .section-offer {
  padding-top: 80px;
}
section.o-klinice-section-content .section-gallery,
section.offer-section-content .section-gallery {
  padding-top: 60px;
}
section.o-klinice-section-content .section-element,
section.offer-section-content .section-element {
  padding-top: 50px;
}
section.o-klinice-section-content .section-element h3,
section.offer-section-content .section-element h3 {
  font-size: 28px;
  line-height: 40px;
  padding: 20px 0;
  font-weight: 600;
}
section.o-klinice-section-content .section-element h4,
section.offer-section-content .section-element h4 {
  font-size: 20px;
  line-height: 30px;
  padding: 30px 0 20px 0;
  font-weight: 600;
}
section.o-klinice-section-content .section-element p,
section.offer-section-content .section-element p {
  font-size: 15px;
  line-height: 26px;
}
section.o-klinice-section-content .section-element p:not(:first-of-type),
section.offer-section-content .section-element p:not(:first-of-type) {
  margin-top: 20px;
}
section.o-klinice-section-content .section-element:nth-of-type(2),
section.offer-section-content .section-element:nth-of-type(2) {
  margin-top: 100px;
  padding-top: 0;
}
section.o-klinice-section-content .section-element .service-steps,
section.offer-section-content .section-element .service-steps {
  padding-top: 30px;
}
section.o-klinice-section-content .section-element .service-steps h2,
section.offer-section-content .section-element .service-steps h2 {
  text-align: center;
  font-size: 38px;
  line-height: 50px;
}
section.o-klinice-section-content .section-element .service-steps ul,
section.offer-section-content .section-element .service-steps ul {
  display: flex;
  justify-content: space-between;
  padding: 40px 0 30px 20px;
  list-style: none;
}
section.o-klinice-section-content .section-element .service-steps ul li,
section.offer-section-content .section-element .service-steps ul li {
  display: flex;
  text-align: center;
}
section.o-klinice-section-content .section-element .service-steps ul li:before,
section.offer-section-content .section-element .service-steps ul li:before {
  content: url(../img/icons/tick.svg);
  background-color: transparent;
  height: 0;
  transform: translate(-10px, 3px);
}
section.o-klinice-section-content .section-element ul,
section.offer-section-content .section-element ul {
  padding-left: 20px;
  list-style-type: none;
}
section.o-klinice-section-content .section-element ul li,
section.offer-section-content .section-element ul li {
  font-size: 15px;
  line-height: 26px;
  padding: 5px 0;
}
section.o-klinice-section-content .section-element ul li:before,
section.offer-section-content .section-element ul li:before {
  display: block;
  content: '';
  width: 15px;
  height: 1px;
  transform: translate(-25px, 15px);
  background-color: #4d95b3;
}
section.o-klinice-section-content .section-element ul + p,
section.offer-section-content .section-element ul + p {
  margin-top: 20px;
}
section.o-klinice-section-content .section-cta,
section.offer-section-content .section-cta {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 15px;
  width: 70%;
  margin: 100px auto 0 auto;
  border: 0.5px solid #000;
  background-color: #f9f9f9;
}
section.o-klinice-section-content .section-cta .cta-heading h2,
section.offer-section-content .section-cta .cta-heading h2 {
  font-size: 38px;
  font-weight: 700;
  text-align: center;
}
section.o-klinice-section-content .section-cta p,
section.offer-section-content .section-cta p {
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}
section.o-klinice-section-content .section-cta .btn-default,
section.offer-section-content .section-cta .btn-default {
  margin-top: 30px;
}
section.static-page-section-content .section-wrapper {
  padding-top: 60px;
}
section.static-page-section-content .section-wrapper p {
  margin-top: 10px;
  font-size: 15px;
  line-height: 26px;
}
section.static-page-section-content .section-wrapper ul,
section.static-page-section-content .section-wrapper ol {
  padding-left: 20px;
  list-style-type: none;
}
section.static-page-section-content .section-wrapper ul li,
section.static-page-section-content .section-wrapper ol li {
  font-size: 15px;
  line-height: 26px;
  padding: 5px 0;
}
section.static-page-section-content .section-wrapper ul li:before,
section.static-page-section-content .section-wrapper ol li:before {
  display: block;
  content: '';
  width: 15px;
  height: 1px;
  transform: translate(-25px, 15px);
  background-color: #4d95b3;
}
section.kontakt-section-content .section-wrapper {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.kontakt-section-content .section-wrapper h2 {
  font-size: 38px;
  font-weight: 600;
  text-align: center;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  width: 40%;
}
section.kontakt-section-content .section-wrapper .section-text {
  width: 100%;
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.kontakt-section-content .section-wrapper .section-text .text-element {
  width: 24%;
  text-align: center;
}
section.kontakt-section-content .section-wrapper .section-text .text-element address {
  font-size: 17px;
  margin: 0;
}
section.kontakt-section-content .section-wrapper .section-text .text-element h3 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}
section.kontakt-section-content .section-wrapper .section-text .text-element ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.kontakt-section-content .section-wrapper .section-text .text-element ul li {
  font-size: 17px;
}
section.kontakt-section-content .section-wrapper .section-text .text-element ul li a {
  display: flex;
  align-items: center;
  transition: color 350ms;
}
section.kontakt-section-content .section-wrapper .section-text .text-element ul li a:hover {
  color: #4d95b3;
}
section.kontakt-section-content .section-wrapper .section-text .text-element ul li a img {
  width: 14px;
  margin-right: 10px;
}
section.kontakt-section-content .section-wrapper .section-map {
  width: 100%;
  margin-top: 70px;
}
section.kontakt-section-content .section-wrapper .section-map iframe {
  width: 100%;
  height: 300px;
}
section.section-footer {
  padding-top: 150px;
  z-index: 222;
  position: relative;
}
section.section-footer .lines {
  position: absolute;
  top: -240px;
  width: 400px;
  height: 550px;
  border-right: 1.5px solid #000;
  border-bottom: 1.5px solid #000;
  right: 280px;
  opacity: 0.7;
  z-index: -1;
}
section.section-footer h2 {
  font-size: 42px;
  font-weight: 600;
  margin-bottom: 20px;
}
section.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
  height: 500px;
}
section.section-footer .footer-flexbox .flexbox-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  padding: 5px 0;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 15px;
  font-weight: 600;
  transition: color 350ms;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #4d95b3;
}
section.section-footer .footer-flexbox .flexbox-service ul li a .icon {
  width: 12px;
  margin-right: 10px;
}
section.section-footer .footer-flexbox .flexbox-service:first-of-type {
  width: 30%;
}
section.section-footer .footer-flexbox .flexbox-service:last-of-type {
  width: 60%;
  background-color: #c4e1ed;
  align-items: center;
  justify-content: flex-start;
  padding-top: 80px;
}
section.section-footer .footer-flexbox .flexbox-service:last-of-type ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.section-footer .footer-flexbox .flexbox-service:last-of-type .logo {
  height: 120px;
  margin-bottom: 50px;
}
section.section-footer .footer-flexbox .flexbox-service:last-of-type address {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
section.section-footer .alert {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  margin-top: 20px;
  padding: 10px 0;
}
section.section-footer .alert p {
  font-size: 12.5px;
  font-weight: 400;
  text-align: center;
}
section.section-footer .alert .alert-links {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
section.section-footer .alert .alert-links a {
  cursor: pointer;
  transition: color 350ms;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 600;
}
section.section-footer .alert .alert-links a:hover {
  color: #4d95b3;
}
section.section-footer .footer-credits-wrapper {
  background-color: #f9f9f9;
}
section.section-footer .footer-credits-wrapper .footer-credits {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;
}
section.section-footer .footer-credits-wrapper .footer-credits p {
  font-size: 13px;
}
section.section-footer .footer-credits-wrapper .footer-credits p a {
  font-weight: 700;
  transition: color 350ms;
}
@media (max-width: 1370.98px) {
  .image-background {
    height: 112%;
    bottom: -30px;
    left: -70px;
    width: 100%;
  }
  section.section-about {
    padding-top: 80px;
  }
  section.section-footer .lines {
    right: 30px;
    top: -200px;
  }
}
@media (max-width: 991.98px) {
  .section-gallery .gallery-wrapper .item {
    margin-top: 3px;
    width: 49.7%;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text {
    width: 60%;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-img {
    width: 40%;
    opacity: 0.7;
  }
  .section-about {
    padding-top: 100px;
  }
  .section-about .about-wrapper {
    flex-direction: column;
  }
  .section-about .about-wrapper .about-text {
    width: 100%;
    margin-top: 60px;
    padding: 0;
  }
  .section-about .about-wrapper .about-img {
    width: 65%;
  }
  .section-offer {
    padding-top: 80px;
  }
  .section-offer .offer-wrapper .offer-img,
  .section-offer .offer-wrapper .offer-heading {
    display: none;
  }
  .section-offer .offer-wrapper .offer-content {
    width: 100%;
  }
  section.section-testimonials .owl-carousel-testimonials .item {
    width: 100%;
  }
  section.section-pricelist .pricelist-column,
  section.cennik-section-content .pricelist-column {
    width: 100%;
  }
  section.o-klinice-section-content .section-about {
    padding-top: 100px;
  }
  section.o-klinice-section-content .section-offer,
  section.o-klinice-section-content .section-gallery {
    padding-top: 60px;
  }
  section.o-klinice-section-content .section-element:nth-of-type(2) {
    margin-top: 40px;
  }
}
@media (max-width: 767.98px) {
  nav.section-header {
    background-color: #fff;
  }
  nav.section-header .header-top {
    display: none;
  }
  nav.section-header .header-flexbox {
    justify-content: space-between;
  }
  nav.section-header .header-flexbox .flexbox-logo-mobile {
    display: flex;
  }
  nav.section-header .header-flexbox .flexbox-logo-mobile img {
    width: 150px;
  }
  nav.section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
    z-index: -1;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .color-element-mobile {
    display: block;
    z-index: -1;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    display: flex;
    flex-direction: column;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    padding: 10px 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 17px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.desktop {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile .collapse-list {
    margin-top: 10px;
    text-align: center;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile .collapse-list li {
    padding: 7px 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.mobile .collapse-list li a {
    font-size: 14px;
    color: #000;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.flexbox-logo {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  .section-heading {
    padding: 40px 0;
  }
  .section-heading h1 {
    width: 70%;
    font-size: 36px;
  }
  .image-background {
    left: -70px;
  }
  section.section-welcome {
    height: 60vh;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text {
    transform: translate(0);
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text h2 {
    font-size: 50px;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text p {
    font-size: 15px;
    line-height: 26px;
  }
  .section-about .about-wrapper .about-img {
    width: 90%;
    height: 400px;
  }
  .section-about .about-wrapper .about-img img {
    object-fit: cover;
    object-position: 20% 0%;
  }
  section.o-klinice-section-content .section-about,
  section.offer-section-content .section-about {
    padding-top: 80px;
  }
  section.o-klinice-section-content .section-element:nth-of-type(2),
  section.offer-section-content .section-element:nth-of-type(2) {
    margin-top: 40px;
  }
  section.o-klinice-section-content .section-element h2,
  section.offer-section-content .section-element h2 {
    font-size: 30px;
    padding: 10px 0;
  }
  section.o-klinice-section-content .section-element .service-steps ul,
  section.offer-section-content .section-element .service-steps ul {
    flex-direction: column;
    padding: 20px 0 30px 20px;
  }
  section.o-klinice-section-content .section-element .service-steps ul li,
  section.offer-section-content .section-element .service-steps ul li {
    text-align: left;
  }
  section.o-klinice-section-content .section-cta,
  section.offer-section-content .section-cta {
    width: 100%;
  }
  section.o-klinice-section-content .section-cta .cta-heading h2,
  section.offer-section-content .section-cta .cta-heading h2 {
    font-size: 36px;
  }
  section.o-klinice-section-content .section-cta p,
  section.offer-section-content .section-cta p {
    font-size: 16px;
  }
  section.kontakt-section-content .section-wrapper {
    padding-top: 40px;
  }
  section.kontakt-section-content .section-wrapper h2 {
    width: 82%;
    font-size: 26px;
  }
  section.kontakt-section-content .section-wrapper .section-text {
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  section.kontakt-section-content .section-wrapper .section-text .text-element {
    width: 100%;
  }
  section.kontakt-section-content .section-wrapper .section-text .text-element:not(:first-of-type) {
    margin-top: 30px;
  }
  section.kontakt-section-content .section-wrapper .section-map {
    margin-top: 40px;
  }
  section.section-footer .lines {
    top: -330px;
  }
  section.section-footer h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    height: auto;
  }
  section.section-footer .footer-flexbox .flexbox-service:first-of-type {
    width: 100%;
    order: 2;
    margin-top: 20px;
    text-align: center;
  }
  section.section-footer .footer-flexbox .flexbox-service:first-of-type ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section.section-footer .footer-flexbox .flexbox-service:first-of-type ul li a {
    font-size: 14px;
  }
  section.section-footer .footer-flexbox .flexbox-service:last-of-type {
    width: 100%;
    order: 1;
    padding: 40px 0;
  }
  section.section-footer .footer-flexbox .flexbox-service:last-of-type .logo {
    height: 90px;
    margin-bottom: 30px;
  }
  section.section-footer .footer-credits-wrapper .footer-credits {
    display: flex;
    justify-content: center;
  }
  section.section-footer .footer-credits-wrapper .footer-credits p {
    font-size: 11px;
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .section-gallery .gallery-wrapper .item {
    margin-top: 6px;
    width: 100%;
    height: 220px;
  }
  .section-heading h1 {
    width: 70%;
    text-align: center;
    font-size: 32px;
  }
  section.section-welcome {
    height: 70vh;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text {
    width: 90%;
    margin: 0 auto;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-img {
    display: none;
  }
  .section-about .about-wrapper .about-img .lines {
    display: none;
  }
  .section-about .about-wrapper .about-text {
    text-align: center;
  }
  .section-offer {
    padding-top: 40px;
  }
  .section-offer .offer-wrapper {
    height: auto;
  }
  .section-offer .offer-wrapper .offer-content .offer-item {
    width: 49%;
    padding: 15px 0 30px 0;
  }
  section.o-klinice-section-content .section-offer,
  section.offer-section-content .section-offer {
    padding-top: 20px;
  }
  section.o-klinice-section-content .section-element,
  section.offer-section-content .section-element {
    padding-top: 20px;
  }
  section.o-klinice-section-content .section-element:nth-of-type(2),
  section.offer-section-content .section-element:nth-of-type(2) {
    margin-top: 20px;
  }
  section.o-klinice-section-content .section-element h3,
  section.offer-section-content .section-element h3,
  section.o-klinice-section-content .section-element h4,
  section.offer-section-content .section-element h4,
  section.o-klinice-section-content .section-element p,
  section.offer-section-content .section-element p {
    text-align: center;
  }
  section.o-klinice-section-content .section-element .service-steps h2,
  section.offer-section-content .section-element .service-steps h2 {
    font-size: 28px;
    line-height: 40px;
  }
  section.o-klinice-section-content .section-gallery,
  section.offer-section-content .section-gallery {
    padding-top: 20px;
  }
  section.o-klinice-section-content .section-cta,
  section.offer-section-content .section-cta {
    margin: 50px 0 0 0;
  }
  section.o-klinice-section-content .section-cta .cta-heading h2,
  section.offer-section-content .section-cta .cta-heading h2 {
    font-size: 30px;
  }
  section.o-klinice-section-content .section-cta p,
  section.offer-section-content .section-cta p {
    font-size: 15px;
  }
  section.o-klinice-section-content .section-cta .btn-default,
  section.offer-section-content .section-cta .btn-default {
    margin-top: 20px;
  }
  section.section-pricelist,
  section.section-gallery {
    padding-top: 60px;
  }
  section.section-pricelist .pricelist-column .pricelist-item .content-heading,
  section.cennik-section-content .pricelist-column .pricelist-item .content-heading {
    padding: 40px 0 40px 0;
  }
  section.section-pricelist .pricelist-column .pricelist-item:first-of-type .content-heading,
  section.cennik-section-content .pricelist-column .pricelist-item:first-of-type .content-heading {
    padding: 20px 0 40px 0;
  }
  section.section-footer {
    padding-top: 60px;
  }
  section.section-footer .lines {
    display: none;
  }
}
@media (max-width: 400px) {
  .image-background {
    height: 115%;
    bottom: -23px;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text {
    padding-top: 30px;
  }
  section.section-welcome .welcome-wrapper .owl-carousel-welcome .item .item-text h2 {
    font-size: 40px;
    line-height: 48px;
  }
  .section-about .about-wrapper .about-img {
    height: 300px;
  }
  .section-about .about-wrapper .about-text h2 {
    font-size: 20px;
    line-height: 40px;
  }
  .section-about .about-wrapper .about-text h2 strong {
    font-size: 36px;
  }
  .section-offer .offer-wrapper .offer-content .offer-item {
    width: 100%;
  }
  section.section-testimonials {
    padding-top: 60px;
  }
  section.section-testimonials .owl-carousel-testimonials .item {
    text-align: center;
    padding: 0 10px;
  }
  section.section-testimonials .owl-carousel-testimonials .item p {
    font-size: 15px;
  }
  section.section-testimonials .owl-carousel-testimonials .item h3 {
    font-size: 20px;
  }
  section.section-testimonials .owl-carousel-testimonials .item span {
    font-size: 18px;
  }
  section.section-testimonials .owl-carousel-testimonials .item img {
    width: 50px;
  }
  section.section-testimonials .owl-carousel-testimonials .owl-dots {
    width: 30%;
    margin-top: 10px;
  }
  .section-gallery .heading {
    margin-bottom: 20px;
  }
  section.section-pricelist .heading,
  section.cennik-section-content .heading {
    margin-bottom: 0;
  }
  section.section-pricelist .pricelist-column .content-row .price,
  section.cennik-section-content .pricelist-column .content-row .price {
    font-size: 14px;
  }
  section.section-pricelist .pricelist-column .content-row .offer,
  section.cennik-section-content .pricelist-column .content-row .offer {
    width: 70%;
    font-size: 13px;
  }
}
