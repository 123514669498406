// Large devices (desktops, less than 1399px)
@media (max-width: 1370.98px) {
	.image-background {
		height: 112%;
		bottom: -30px;
		left: -70px;
		width: 100%;
	}

	section.section-about {
		padding-top: 80px;
	}

	section.section-footer {
		.lines {
			right: 30px;
			top: -200px;
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	.section-gallery {
		.gallery-wrapper {
			.item {
				margin-top: 3px;
				width: 49.7%;
			}
		}
	}
	section.section-welcome {
		.welcome-wrapper {
			.owl-carousel-welcome {
				.item {
					.item-text {
						width: 60%;
					}
					.item-img {
						width: 40%;
						opacity: 0.7;
					}
				}
			}
		}
	}

	.section-about {
		padding-top: 100px;
		.about-wrapper {
			flex-direction: column;

			.about-text {
				width: 100%;
				margin-top: 60px;
				padding: 0;
			}
			.about-img {
				width: 65%;
			}
		}
	}

	.section-offer {
		padding-top: 80px;
		.offer-wrapper {
			.offer-img,
			.offer-heading {
				display: none;
			}
			.offer-content {
				width: 100%;
			}
		}
	}

	section.section-testimonials {
		.owl-carousel-testimonials {
			.item {
				width: 100%;
			}
		}
	}

	section.section-pricelist,
	section.cennik-section-content {
		.pricelist-column {
			width: 100%;
		}
	}

	section.o-klinice-section-content {
		.section-about {
			padding-top: 100px;
		}

		.section-offer,
		.section-gallery {
			padding-top: 60px;
		}
		.section-element {
			&:nth-of-type(2) {
				margin-top: 40px;
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	nav.section-header {
		background-color: #fff;
		.header-top {
			display: none;
		}
		.header-flexbox {
			justify-content: space-between;

			.flexbox-logo-mobile {
				display: flex;
				img {
					width: 150px;
				}
			}

			.nav-button {
				display: flex;
				margin-right: -10px;
			}

			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 555;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;
					z-index: -1;

					.color-element-mobile {
						display: block;
						z-index: -1;
					}

					.flexbox-nav {
						display: flex;
						flex-direction: column;

						li {
							padding: 10px 0;

							a {
								font-size: 17px;
							}

							&.desktop {
								display: none;
							}

							&.mobile {
								display: flex;
								flex-direction: column;
								align-items: center;
								.collapse-list {
									margin-top: 10px;
									text-align: center;

									li {
										padding: 7px 0;

										a {
											font-size: 14px;
											color: #000;
										}
									}
								}
							}

							&.flexbox-logo {
								display: none;
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	.section-heading {
		padding: 40px 0;
		h1 {
			width: 70%;
			font-size: 36px;
		}
	}

	.image-background {
		left: -70px;
	}

	section.section-welcome {
		height: 60vh;
		.welcome-wrapper {
			.owl-carousel-welcome {
				.item {
					.item-text {
						transform: translate(0);
						h2 {
							font-size: 50px;
						}
						p {
							font-size: 15px;
							line-height: 26px;
						}
					}
				}
			}
		}
	}

	.section-about {
		.about-wrapper {
			.about-img {
				width: 90%;
				height: 400px;
				img {
					object-fit: cover;
					object-position: 20% 0%;
				}
			}
		}
	}

	section.o-klinice-section-content,
	section.offer-section-content {
		.section-about {
			padding-top: 80px;
		}

		.section-element {
			&:nth-of-type(2) {
				margin-top: 40px;
			}

			h2 {
				font-size: 30px;
				padding: 10px 0;
			}

			.service-steps {
				ul {
					flex-direction: column;
					padding: 20px 0 30px 20px;
					li {
						text-align: left;
					}
				}
			}
		}

		.section-cta {
			width: 100%;

			.cta-heading {
				h2 {
					font-size: 36px;
				}
			}

			p {
				font-size: 16px;
			}
		}
	}

	section.kontakt-section-content {
		.section-wrapper {
			padding-top: 40px;

			h2 {
				width: 82%;
				font-size: 26px;
			}

			.section-text {
				padding-top: 30px;
				flex-direction: column;
				align-items: center;

				.text-element {
					width: 100%;

					&:not(:first-of-type) {
						margin-top: 30px;
					}
				}
			}

			.section-map {
				margin-top: 40px;
			}
		}
	}

	section.section-footer {
		.lines {
			top: -330px;
		}

		h2 {
			font-size: 36px;
			margin-bottom: 10px;
		}

		.footer-flexbox {
			flex-direction: column;
			height: auto;

			.flexbox-service {
				&:first-of-type {
					width: 100%;
					order: 2;
					margin-top: 20px;
					text-align: center;

					ul {
						display: flex;
						flex-direction: column;
						align-items: center;

						li {
							a {
								font-size: 14px;
							}
						}
					}
				}

				&:last-of-type {
					width: 100%;
					order: 1;
					padding: 40px 0;

					.logo {
						height: 90px;
						margin-bottom: 30px;
					}
				}
			}
		}

		.footer-credits-wrapper {
			.footer-credits {
				display: flex;
				justify-content: center;
				p {
					font-size: 11px;
					text-align: center;
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.section-gallery {
		.gallery-wrapper {
			.item {
				margin-top: 6px;
				width: 100%;
				height: 220px;
			}
		}
	}
	.section-heading {
		h1 {
			width: 70%;
			text-align: center;
			font-size: 32px;
		}
	}

	section.section-welcome {
		height: 70vh;
		.welcome-wrapper {
			.owl-carousel-welcome {
				.item {
					.item-text {
						width: 90%;
						margin: 0 auto;
					}
					.item-img {
						display: none;
					}
				}
			}
		}
	}

	.section-about {
		.about-wrapper {
			.about-img {
				.lines {
					display: none;
				}
			}
			.about-text {
				text-align: center;
			}
		}
	}

	.section-offer {
		padding-top: 40px;
		.offer-wrapper {
			height: auto;
			.offer-content {
				.offer-item {
					width: 49%;
					padding: 15px 0 30px 0;
				}
			}
		}
	}

	section.o-klinice-section-content,
	section.offer-section-content {
		.section-offer {
			padding-top: 20px;
		}
		.section-element {
			padding-top: 20px;
			&:nth-of-type(2) {
				margin-top: 20px;
			}

			h3,
			h4,
			p {
				text-align: center;
			}

			.service-steps {
				h2 {
					font-size: 28px;
					line-height: 40px;
				}
			}
		}

		.section-gallery {
			padding-top: 20px;
		}

		.section-cta {
			margin: 50px 0 0 0;
			.cta-heading {
				h2 {
					font-size: 30px;
				}
			}

			p {
				font-size: 15px;
			}

			.btn-default {
				margin-top: 20px;
			}
		}
	}

	section.section-pricelist,
	section.section-gallery {
		padding-top: 60px;
	}

	section.section-pricelist,
	section.cennik-section-content {
		.pricelist-column {
			.pricelist-item {
				.content-heading {
					padding: 40px 0 40px 0;
				}
				&:first-of-type {
					.content-heading {
						padding: 20px 0 40px 0;
					}
				}
			}
		}
	}

	section.section-footer {
		padding-top: 60px;
		.lines {
			display: none;
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {
	.image-background {
		height: 115%;
		bottom: -23px;
	}

	section.section-welcome {
		.welcome-wrapper {
			.owl-carousel-welcome {
				.item {
					.item-text {
						padding-top: 30px;
						h2 {
							font-size: 40px;
							line-height: 48px;
						}
					}
				}
			}
		}
	}

	.section-about {
		.about-wrapper {
			.about-img {
				height: 300px;
			}
			.about-text {
				h2 {
					font-size: 20px;
					line-height: 40px;
					strong {
						font-size: 36px;
					}
				}
			}
		}
	}

	.section-offer {
		.offer-wrapper {
			.offer-content {
				.offer-item {
					width: 100%;
				}
			}
		}
	}

	section.section-testimonials {
		padding-top: 60px;
		.owl-carousel-testimonials {
			.item {
				text-align: center;
				padding: 0 10px;

				p {
					font-size: 15px;
				}

				h3 {
					font-size: 20px;
				}

				span {
					font-size: 18px;
				}

				img {
					width: 50px;
				}
			}

			.owl-dots {
				width: 30%;
				margin-top: 10px;
			}
		}
	}

	.section-gallery {
		.heading {
			margin-bottom: 20px;
		}
	}

	section.section-pricelist,
	section.cennik-section-content {
		.heading {
			margin-bottom: 0;
		}
		.pricelist-column {
			.content-row {
				.price {
					font-size: 14px;
				}
				.offer {
					width: 70%;
					font-size: 13px;
				}
			}
		}
	}
}
