// @import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond:400,600,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap&subset=latin-ext');

body {
	min-height: 100vh;
	height: 100%;
	// font-family: 'Cormorant Garamond', serif;
	font-family: 'Open Sans', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	ul,
	ol,
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
		color: #000;
	}

	a {
		text-decoration: none !important;
		color: #000;
	}
}

.alert-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.2);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;

	.alert-service {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		width: 100%;
		transform: translateX(8px);
		padding: 50px 0;

		.service-inner {
			padding: 50px;
			background: #fff;
			width: 100%;
			box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
			text-align: center;
			border-top: 5px solid #fff;
			position: relative;
			transform: scale(0.8);
			transition: all 0.5s;

			.inner-dismiss {
				top: 10px;
				right: 10px;
				position: absolute;
				border: 0;
				background: none;
				padding: 0;
				cursor: pointer;
				display: flex;
				padding: 5px;
				outline: none;

				img {
					height: 15px;
				}
			}

			&.success {
				border-top: 5px solid #78b042;
			}

			&.warning {
				border-top: 5px solid #d51a27;
			}

			&.info {
				border-top: 5px solid #1a88d5;
			}
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;

		.alert-service {
			.service-inner {
				transform: none;
			}
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 700px;
			max-width: 100%;
			padding: 50px 25px 25px 25px;
			text-align: center;
			position: relative;

			.service-button {
				position: absolute;
				top: 20px;
				right: 20px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: #222;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				.heading {
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 20px;
				}

				.btn {
					display: flex;
					justify-content: center;
					margin-top: 5px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

nav.section-header {
	box-shadow: 0 20px 50px rgba(0, 0, 0, 0.04), 0 10px 12px rgba(0, 0, 0, 0.03);
	margin-bottom: 0.5px;
	position: relative;

	.header-top {
		width: 100%;
		padding: 10px 0;
		background-color: @color1;

		ul {
			display: flex;
			justify-content: space-between;
			padding: 0 65px;

			li {
				a,
				p {
					font-weight: 400;
					// font-size: 15px;
					font-size: 13px;
					transition: color 350ms;
					display: flex;
					align-items: center;

					img {
						width: 10px;
						margin-right: 10px;
					}
				}

				&:hover {
					a {
						color: @color2;
					}
				}

				.address {
					display: flex;
					transform: translatex(-20px);

					img {
						width: 10px;
						margin-right: 10px;
					}
				}
			}
		}
	}

	.header-flexbox {
		display: flex;
		padding: 20px 0 10px 0;
		align-items: center;

		.flexbox-logo-mobile {
			display: none;
			img {
				width: 200px;
				transform: translatey(-10px);
			}
		}

		.flexbox-nav-wrapper {
			width: 100%;

			.nav-button-close {
				position: absolute;
				display: none;
				width: 100%;
				top: 30px;
				left: -5px;

				.container {
					display: flex;
					justify-content: flex-end;

					&:before,
					&:after {
						content: none;
					}
				}

				button {
					outline: none;
					border: none;
					background: none;
					padding: 15px;
					display: flex;
					justify-content: center;
					align-items: center;

					.button-bar {
						display: block;
						position: absolute;
						width: 30px;
						height: 2px;
						background: #222;

						&:nth-child(1) {
							transform: rotate(45deg);
						}

						&:nth-child(2) {
							transform: rotate(-45deg);
						}
					}
				}
			}

			.flexbox-nav-inner {
				width: 100%;
				position: relative;

				.color-element-mobile {
					display: none;
					position: absolute;
					top: 0;
					left: 0;
					width: 65px;
					height: 100%;
					background-color: @color2;
				}

				.flexbox-nav {
					display: flex;
					justify-content: space-between;
					align-items: center;

					li {
						&.flexbox-logo {
							img {
								width: 200px;
								transform: translatey(-10px);
							}
						}

						a {
							font-size: 15px;
							letter-spacing: 1px;
							font-weight: 600;
							transition: color 350ms;
						}

						&:hover {
							a {
								color: @color2;
							}
						}

						&.mobile {
							display: none;
						}

						&.desktop {
							.dropdown-toggle {
								font-size: 15px;
								font-weight: 600;
								letter-spacing: 1px;
								padding: 0;
								outline: none;
								border: none;
								background: none;
								cursor: pointer;
								transition: color 350ms;

								&:hover {
									color: @color2;
								}

								&::after {
									display: none;
								}
							}

							.dropdown-menu {
								position: relative;
								background: #7aadc2;
								margin-top: 10px;
								border: none;
								border-radius: 0;

								&:before {
									content: '';
									display: block;
									position: absolute;
									left: 25px;
									top: -5px;
									width: 0;
									height: 0;
									border-style: solid;
									border-width: 0 4px 5px 4px;
									border-color: transparent transparent rgba(#7aadc2, 0.8)
										transparent;
								}

								.dropdown-item {
									padding: 10px 15px;
									font-size: 13px;
									letter-spacing: 1px;
									font-weight: 600;
									transition: all 0.3s;
									transform: none !important;
									color: #000;

									&:hover {
										background: @color1;
									}
								}
							}
						}
					}
				}
			}
		}

		.nav-button {
			padding: 10px;
			border: none;
			background: none;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			outline: none;
			display: none;

			.button-bar {
				height: 1px;
				width: 24px;
				background: #000;
				border-radius: 10px;
				transition: all 0.4s;

				&:nth-child(2) {
					margin-top: 4px;
					width: 27px;
				}

				&:nth-child(3) {
					margin-top: 4px;
					width: 30px;
				}
			}

			&:hover,
			&:focus {
				.button-bar {
					width: 30px !important;
				}
			}
		}
	}
}

// @color1: #eef5ff;
@color1: #c4e1ed;
// @color2: #8ec6c5;
@color2: #4d95b3;
// @color3: #d2e8e8;

.btn {
	border: 0;
	background: 0;
	border-radius: 0;
	padding: 10px 30px;
	font-size: 17px;
	font-weight: 600;
	letter-spacing: 1px;
	transition: all 350ms;

	&.btn-default {
		border: 1px solid #000;
	}

	&:hover {
		background-color: #f9f9f9;
		color: @color2;
	}

	&.light {
		&:hover {
			background-color: #fff;
		}
	}
}

.section-heading {
	width: 100%;
	padding: 60px 0;
	background-color: @color1;
	display: flex;
	align-items: center;

	h1 {
		font-size: 44px;
		font-weight: 600;
		width: 59%;
		margin: 0 auto;
	}
}

.fancybox-caption__body {
	font-size: 26px;
}

.image-background {
	position: absolute;
	bottom: -50px;
	left: -100px;
	z-index: -1;
	width: 110%;
	height: 120%;
	background-color: @color1;
}

section.section-welcome {
	height: 80vh;

	.welcome-background {
		width: 100%;
		height: 100%;
		background-color: @color1;
	}

	.welcome-wrapper {
		height: 100%;

		.owl-carousel-welcome {
			height: 100%;

			.owl-stage-outer,
			.owl-stage,
			.owl-item {
				height: 100%;
			}

			.item {
				height: 100%;
				display: flex;
				align-items: center;

				.item-text {
					position: relative;
					z-index: 1;
					width: 50%;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					transform: translatex(190px);

					h2 {
						font-weight: 600;
						font-size: 60px;
						line-height: 60px;
						margin-bottom: 30px;
						text-align: center;
						width: 100%;
					}

					p {
						font-size: 17px;
						text-align: center;
						width: 100%;
					}

					.btn-default {
						margin-top: 40px;
						align-self: center;
						transition: color 350ms;

						&:hover {
							color: @color2;
						}
					}
				}

				.item-img {
					width: 55%;
					height: 100%;
					background-color: #fff;
					display: flex;
					align-items: flex-start;
					justify-content: flex-end;
				}
			}
		}
	}
}

.section-about {
	padding-top: 150px;

	.about-wrapper {
		display: flex;
		position: relative;
		align-items: center;

		.line {
			position: absolute;
			left: -500px;
			top: 200px;
			width: 800px;
			height: 1px;
			background-color: #000;
		}

		.about-img {
			position: relative;
			z-index: 1;
			width: 40%;
			height: 480px;

			.lines {
				position: absolute;
				top: -480px;
				left: -200px;
				height: 650px;
				width: 400px;
				border-left: 1.5px solid #000;
				border-bottom: 1.5px solid #000;
				opacity: 0.7;
				z-index: -1;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 0 30%;
			}
		}

		.about-text {
			width: 60%;
			padding: 0 0 0 60px;

			h2 {
				font-size: 28px;
				margin-bottom: 30px;
				line-height: 44px;

				strong {
					font-size: 42px;
				}
			}

			h3 {
				font-size: 18px;
				font-weight: 600;
				margin-bottom: 10px;
			}

			p {
				font-size: 15px;
				line-height: 26px;

				&:not(:first-of-type) {
					margin-top: 10px;
				}

				strong {
					font-size: 16px;
				}
			}

			a {
				font-weight: 700;
				transition: color 350ms;

				&:hover {
					color: @color2;
				}
			}

			.btn-default {
				margin-top: 30px;
				font-weight: 600;
			}
		}
	}
}

.section-offer {
	padding-top: 150px;

	.offer-wrapper {
		display: flex;
		height: 450px;

		.offer-content {
			width: 60%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			.offer-item {
				width: 33%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 20px;
				background-color: @color1;
				transition: background-color 350ms;
				margin-top: 3px;

				.icon {
					position: relative;

					.circle {
						position: absolute;
						left: -15px;
						top: -15px;
						width: 75px;
						height: 75px;
						border-radius: 100px;
						background-color: @color2;
						transition: all 350ms;
					}

					img {
						position: relative;
						z-index: 1;
						width: 45px;
						opacity: 0.7;
					}
				}

				h3 {
					font-size: 17px;
					line-height: 26px;
					margin-bottom: 30px;
					text-align: center;
				}

				&:hover {
					background-color: rgba(@color1, 0.3);

					.circle {
						width: 80px;
						height: 80px;
						left: -17px;
						top: -17px;
					}
				}
			}
		}

		.offer-img {
			width: 40%;
			margin-top: 3px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.offer-heading {
			width: 40%;
			margin-top: 3px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-left: 70px;
			text-align: center;

			h2 {
				border-bottom: 1px solid #000;
				padding-bottom: 10px;
				margin-bottom: 20px;
				line-height: 48px;
				width: 80%;
				font-weight: 700;
				font-size: 38px;
			}

			p {
				font-size: 15px;
			}

			.btn-default {
				margin-top: 40px;
			}
		}
	}
}

.section-gallery {
	padding-top: 80px;

	h2 {
		font-size: 42px;
		font-weight: 700;
		text-align: center;
		padding: 30px 0 40px 0;
	}

	.gallery-wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.item {
			position: relative;
			width: 24.8%;
			margin-top: 5px;
			height: 320px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				transition: transform 250ms;
			}

			.img-background {
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(@color2, 0.5);
				transition: all 350ms;

				&:hover {
					background-color: transparent;
				}
			}

			&:nth-of-type(odd) {
				.img-background {
					background-color: rgba(@color2, 0.2);

					&:hover {
						background-color: transparent;
					}
				}
			}
		}
	}
}

section.section-testimonials {
	padding-top: 100px;

	.owl-carousel-testimonials {
		.item {
			width: 70%;
			margin: 0 auto 40px auto;
			display: flex;
			flex-direction: column;
			align-items: center;

			img {
				width: 70px;
				opacity: 0.7;
				margin-bottom: 30px;
			}

			h3 {
				font-size: 22px;
				margin-bottom: 10px;
			}

			p {
				text-align: center;
				font-size: 15px;
				line-height: 26px;
				margin-top: 10px;
			}

			span {
				font-size: 20px;
				font-weight: 700;
				display: block;
				text-align: center;
				margin-top: 40px;
			}
		}

		.owl-dots {
			width: 20%;
			margin: 0 auto;
			display: flex;
			justify-content: space-around;
			align-items: center;
			margin-top: 10px;

			.owl-dot {
				width: 10px;
				height: 10px;
				border-radius: 0;
				border: 1px solid #000;

				&.active {
					width: 18px;
					height: 18px;
					border: none;
					background-color: @color2;
				}
			}
		}
	}
}

section.section-pricelist {
	padding-top: 100px;
}

section.section-pricelist,
section.cennik-section-content {
	.heading {
		text-align: center;

		h2 {
			font-size: 42px;
			font-weight: 700;
			margin-bottom: 20px;
		}

		p {
			font-size: 15px;
			line-height: 26px;
		}
	}

	.pricelist-column {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 85%;
		margin: 0 auto;
		padding-top: 40px;

		.pricelist-item {
			display: flex;
			flex-direction: column;
		}

		.content-heading {
			padding: 60px 0 40px 0;

			h2 {
				text-align: center;
				font-weight: 600;
			}
		}

		.content-row {
			display: flex;
			justify-content: space-between;
			border-bottom: 0.5px solid @color2;
			padding-bottom: 10px;
			margin: 10px 0;

			.offer {
				display: inline-block;
				font-size: 15px;
				text-align: left;
			}

			.price {
				font-weight: 600;
				font-size: 15px;
				text-align: right;
			}
		}

		.btn-default {
			margin-top: 40px;
			align-self: center;
		}
	}
}

section.o-klinice-section-content,
section.offer-section-content {
	.section-wrapper {
		padding-top: 80px;
		display: flex;
	}

	h2 {
		font-size: 42px;
		font-weight: 700;
	}

	.section-offer {
		padding-top: 80px;
	}

	.section-gallery {
		padding-top: 60px;
	}

	.section-element {
		padding-top: 50px;

		h3 {
			font-size: 28px;
			line-height: 40px;
			padding: 20px 0;
			font-weight: 600;
		}

		h4 {
			font-size: 20px;
			line-height: 30px;
			padding: 30px 0 20px 0;
			font-weight: 600;
		}

		p {
			font-size: 15px;
			line-height: 26px;
			&:not(:first-of-type) {
				margin-top: 20px;
			}
		}

		&:nth-of-type(2) {
			margin-top: 100px;
			padding-top: 0;
		}

		.service-steps {
			padding-top: 30px;

			h2 {
				text-align: center;
				font-size: 38px;
				line-height: 50px;
			}

			ul {
				display: flex;
				justify-content: space-between;
				padding: 40px 0 30px 20px;
				list-style: none;

				li {
					display: flex;
					text-align: center;

					&:before {
						content: url(../img/icons/tick.svg);
						background-color: transparent;
						height: 0;
						transform: translate(-10px, 3px);
					}
				}
			}
		}

		ul {
			padding-left: 20px;
			list-style-type: none;

			li {
				font-size: 15px;
				line-height: 26px;
				padding: 5px 0;

				&:before {
					display: block;
					content: '';
					width: 15px;
					height: 1px;
					transform: translate(-25px, 15px);
					background-color: @color2;
				}
			}
		}

		ul + p {
			margin-top: 20px;
		}
	}

	.section-cta {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 60px 15px;
		width: 70%;
		margin: 100px auto 0 auto;
		border: 0.5px solid #000;
		background-color: #f9f9f9;

		.cta-heading {
			h2 {
				font-size: 38px;
				font-weight: 700;
				text-align: center;
			}
		}

		p {
			font-size: 18px;
			text-align: center;
			margin-top: 20px;
		}

		.btn-default {
			margin-top: 30px;
		}
	}
}

section.static-page-section-content {
	.section-wrapper {
		padding-top: 60px;

		p {
			margin-top: 10px;
			font-size: 15px;
			line-height: 26px;
		}

		ul,
		ol {
			padding-left: 20px;
			list-style-type: none;

			li {
				font-size: 15px;
				line-height: 26px;
				padding: 5px 0;

				&:before {
					display: block;
					content: '';
					width: 15px;
					height: 1px;
					transform: translate(-25px, 15px);
					background-color: @color2;
				}
			}
		}
	}
}

section.kontakt-section-content {
	.section-wrapper {
		padding-top: 60px;
		display: flex;
		flex-direction: column;
		align-items: center;

		h2 {
			font-size: 38px;
			font-weight: 600;
			text-align: center;
			border-bottom: 1px solid #000;
			padding-bottom: 10px;
			width: 40%;
		}

		.section-text {
			width: 100%;
			padding-top: 60px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			.text-element {
				width: 24%;
				text-align: center;

				address {
					font-size: 17px;
					margin: 0;
				}

				h3 {
					margin-bottom: 10px;
					font-size: 18px;
					font-weight: 600;
				}

				ul {
					display: flex;
					flex-direction: column;
					align-items: center;

					li {
						font-size: 17px;

						a {
							display: flex;
							align-items: center;
							transition: color 350ms;

							&:hover {
								color: @color2;
							}

							img {
								width: 14px;
								margin-right: 10px;
							}
						}
					}
				}
			}
		}

		.section-map {
			width: 100%;
			margin-top: 70px;
			iframe {
				width: 100%;
				height: 300px;
			}
		}
	}
}

section.section-footer {
	padding-top: 150px;
	z-index: 222;
	position: relative;

	.lines {
		position: absolute;
		top: -240px;
		width: 400px;
		height: 550px;
		border-right: 1.5px solid #000;
		border-bottom: 1.5px solid #000;
		right: 280px;
		opacity: 0.7;
		z-index: -1;
	}

	h2 {
		font-size: 42px;
		font-weight: 600;
		margin-bottom: 20px;
	}

	.footer-flexbox {
		display: flex;
		justify-content: space-between;
		height: 500px;

		.flexbox-service {
			display: flex;
			flex-direction: column;
			justify-content: center;

			ul {
				li {
					padding: 5px 0;

					a {
						font-size: 15px;
						font-weight: 600;
						transition: color 350ms;
						letter-spacing: 1px;
						display: flex;
						align-items: center;

						&:hover {
							color: @color2;
						}

						.icon {
							width: 12px;
							margin-right: 10px;
						}
					}
				}
			}

			&:first-of-type {
				width: 30%;
			}

			&:last-of-type {
				width: 60%;
				background-color: @color1;
				align-items: center;
				justify-content: flex-start;
				padding-top: 80px;

				ul {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.logo {
					height: 120px;
					margin-bottom: 50px;
				}

				address {
					font-size: 18px;
					font-weight: 600;
					text-align: center;
				}
			}
		}
	}

	.alert {
		background: none;
		border: none;
		border-radius: 0;
		padding: 0;
		margin-top: 20px;
		padding: 10px 0;

		p {
			font-size: 12.5px;
			font-weight: 400;
			text-align: center;
		}

		.alert-links {
			margin-top: 10px;
			display: flex;
			justify-content: center;

			a {
				cursor: pointer;
				transition: color 350ms;
				margin-right: 10px;
				font-size: 13px;
				font-weight: 600;

				&:hover {
					color: @color2;
				}
			}
		}
	}

	.footer-credits-wrapper {
		background-color: #f9f9f9;

		.footer-credits {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding: 20px 0;

			p {
				font-size: 13px;

				a {
					font-weight: 700;
					transition: color 350ms;
				}
			}
		}
	}
}
